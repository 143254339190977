<script>
/* eslint-disable */
import {SimpleBar} from 'simplebar-vue3';

import appConfig from '@/app.config';
import PageHeader from '@/components/page-header';

import {required, helpers} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {chatData, chatMessagesData} from './data';
import Layout from '../../layouts/main';

/**
 * Chat Component
 */
export default {
  setup() {
    return {v$: useVuelidate()};
  },
  page: {
    title: 'Chat',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    SimpleBar,
  },
  data() {
    return {
      chatData,
      chatMessagesData,
      title: 'Chat',
      items: [
        {
          text: 'Skote',
          href: '/',
        },
        {
          text: 'Chat',
          active: true,
        },
      ],
      submitted: false,
      form: {
        message: '',
      },
      username: 'Steven Franklin',
    };
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage('Message is required', required),
      },
    },
  },
  methods: {
    /**
     * Get the name of user
     */
    chatUsername(name) {
      this.username = name;
      this.usermessage = 'Hello';
      this.chatMessagesData = [];
      const currentDate = new Date();

      this.chatMessagesData.push({
        name: this.username,
        message: this.usermessage,
        time: `${currentDate.getHours()}:${currentDate.getMinutes()}`,
      });
    },

    /**
     * Char form Submit
     */
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }
      const {message} = this.form;
      const currentDate = new Date();
      this.chatMessagesData.push({
        align: 'right',
        name: 'Henry Wells',
        message,
        time: `${currentDate.getHours()}:${currentDate.getMinutes()}`,
      });
      this.handleScroll();

      this.submitted = false;
      this.form = {};
    },

    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.value.getScrollElement().scrollTop = this.$refs.current.SimpleBar.value.getScrollElement().scrollHeight +
            1500;
        }, 500);
      }
    },
  },
  mounted() {
    const container1 = document.querySelector(
      '#chat-list .simplebar-content-wrapper',
    );
    container1.scrollTo({top: 500, behavior: 'smooth'});

    const container2 = document.querySelector(
      '#containerElement .simplebar-content-wrapper',
    );
    container2.scrollTo({top: 500, behavior: 'smooth'});
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="d-lg-flex">
      <div class="chat-leftsidebar me-lg-4">
        <div class>
          <div class="py-4 border-bottom">
            <div class="d-flex">
              <div class="align-self-center me-3">
                <img
                  alt
                  class="avatar-xs rounded-circle"
                  src="@/assets/images/users/avatar-1.jpg"
                />
              </div>
              <div class="flex-grow-1">
                <h5 class="font-size-15 mt-0 mb-1">Henry Wells</h5>
                <p class="text-muted mb-0">
                  <i class="mdi mdi-circle text-success align-middle me-1"></i>
                  Active
                </p>
              </div>

              <b-dropdown
                class="chat-noti-dropdown active"
                menu-class="dropdown-menu-end"
                right
                variant="white"
              >
                <template v-slot:button-content>
                  <i class="bx bx-bell bx-tada"></i>
                </template>
                <b-dropdown-item>Action</b-dropdown-item>
                <b-dropdown-item>Another action</b-dropdown-item>
                <b-dropdown-item>Something else here</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>

        <div class="search-box chat-search-box py-4">
          <div class="position-relative">
            <input class="form-control" placeholder="Search..." type="text"/>
            <i class="bx bx-search-alt search-icon"></i>
          </div>
        </div>

        <div class="chat-leftsidebar-nav">
          <b-tabs content-class="py-4" fill pills>
            <b-tab active title="Tab 1">
              <template v-slot:title>
                <i class="bx bx-chat font-size-20 d-sm-none"></i>
                <span class="d-none d-sm-block">Chat</span>
              </template>
              <b-card-text>
                <div>
                  <h5 class="font-size-14 mb-3">Recent</h5>
                  <SimpleBar id="chat-list" style="max-height: 410px">
                    <ul class="list-unstyled chat-list">
                      <li
                        v-for="data of chatData"
                        :key="data.id"
                        :class="{ active: username == data.name }"
                        class
                        @click="chatUsername(data.name)"
                      >
                        <a href="javascript: void(0);">
                          <div class="d-flex">
                            <div class="align-self-center me-3">
                              <i
                                :class="`mdi mdi-circle text-${data.color} font-size-10`"
                              ></i>
                            </div>
                            <div
                              v-if="data.image"
                              class="align-self-center me-3"
                            >
                              <img
                                :src="`${data.image}`"
                                alt
                                class="rounded-circle avatar-xs"
                              />
                            </div>
                            <div
                              v-if="!data.image"
                              class="avatar-xs align-self-center me-3"
                            >
                              <span
                                class="
                                  avatar-title
                                  rounded-circle
                                  bg-soft bg-primary
                                  text-primary
                                "
                              >{{ data.name.charAt(0) }}</span
                              >
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                              <h5 class="text-truncate font-size-14 mb-1">
                                {{ data.name }}
                              </h5>
                              <p class="text-truncate mb-0">
                                {{ data.message }}
                              </p>
                            </div>
                            <div class="font-size-11">{{ data.time }}</div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </SimpleBar>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="bx bx-group font-size-20 d-sm-none"></i>
                <span class="d-none d-sm-block">Groups</span>
              </template>
              <b-card-text>
                <h5 class="font-size-14 mb-3">Groups</h5>
                <ul class="list-unstyled chat-list">
                  <li>
                    <a href="#">
                      <div class="d-flex align-items-center">
                        <div class="avatar-xs me-3">
                          <span
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft bg-primary
                              text-primary
                            "
                          >G</span
                          >
                        </div>

                        <div class="flex-grow-1">
                          <h5 class="font-size-14 mb-0">General</h5>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div class="d-flex align-items-center">
                        <div class="avatar-xs me-3">
                          <span
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft bg-primary
                              text-primary
                            "
                          >R</span
                          >
                        </div>

                        <div class="flex-grow-1">
                          <h5 class="font-size-14 mb-0">Reporting</h5>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div class="d-flex align-items-center">
                        <div class="avatar-xs me-3">
                          <span
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft bg-primary
                              text-primary
                            "
                          >M</span
                          >
                        </div>

                        <div class="flex-grow-1">
                          <h5 class="font-size-14 mb-0">Meeting</h5>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div class="d-flex align-items-center">
                        <div class="avatar-xs me-3">
                          <span
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft bg-primary
                              text-primary
                            "
                          >A</span
                          >
                        </div>

                        <div class="flex-grow-1">
                          <h5 class="font-size-14 mb-0">Project A</h5>
                        </div>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <div class="d-flex align-items-center">
                        <div class="avatar-xs me-3">
                          <span
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft bg-primary
                              text-primary
                            "
                          >B</span
                          >
                        </div>

                        <div class="flex-grow-1">
                          <h5 class="font-size-14 mb-0">Project B</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </b-card-text>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="bx bx-book-content font-size-20 d-sm-none"></i>
                <span class="d-none d-sm-block">Contacts</span>
              </template>
              <b-card-text>
                <h5 class="font-size-14 mb-3">Contacts</h5>
                <SimpleBar style="height: 410px">
                  <div>
                    <div class="avatar-xs mb-3">
                      <span
                        class="
                          avatar-title
                          rounded-circle
                          bg-soft bg-primary
                          text-primary
                        "
                      >A</span
                      >
                    </div>

                    <ul class="list-unstyled chat-list">
                      <li>
                        <a href="#">
                          <h5 class="font-size-14 mb-0">Adam Miller</h5>
                        </a>
                      </li>

                      <li>
                        <a href="#">
                          <h5 class="font-size-14 mb-0">Alfonso Fisher</h5>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="mt-4">
                    <div class="avatar-xs mb-3">
                      <span
                        class="
                          avatar-title
                          rounded-circle
                          bg-soft bg-primary
                          text-primary
                        "
                      >B</span
                      >
                    </div>

                    <ul class="list-unstyled chat-list">
                      <li>
                        <a href="#">
                          <h5 class="font-size-14 mb-0">Bonnie Harney</h5>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="mt-4">
                    <div class="avatar-xs mb-3">
                      <span
                        class="
                          avatar-title
                          rounded-circle
                          bg-soft bg-primary
                          text-primary
                        "
                      >C</span
                      >
                    </div>

                    <ul class="list-unstyled chat-list">
                      <li>
                        <a href="#">
                          <h5 class="font-size-14 mb-0">Charles Brown</h5>
                        </a>
                        <a href="#">
                          <h5 class="font-size-14 mb-0">Carmella Jones</h5>
                        </a>
                        <a href="#">
                          <h5 class="font-size-14 mb-0">Carrie Williams</h5>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="mt-4">
                    <div class="avatar-xs mb-3">
                      <span
                        class="
                          avatar-title
                          rounded-circle
                          bg-soft bg-primary
                          text-primary
                        "
                      >D</span
                      >
                    </div>

                    <ul class="list-unstyled chat-list">
                      <li>
                        <a href="#">
                          <h5 class="font-size-14 mb-0">Dolores Minter</h5>
                        </a>
                      </li>
                    </ul>
                  </div>
                </SimpleBar>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="w-100 user-chat">
        <div class="card">
          <div class="p-4 border-bottom">
            <div class="row">
              <div class="col-md-4 col-9">
                <h5 class="font-size-15 mb-1">{{ username }}</h5>
                <p class="text-muted mb-0">
                  <i class="mdi mdi-circle text-success align-middle me-1"></i>
                  Active now
                </p>
              </div>
              <div class="col-md-8 col-3">
                <ul class="list-inline user-chat-nav text-end mb-0">
                  <li class="list-inline-item d-none d-sm-inline-block">
                    <b-dropdown
                      menu-class="dropdown-menu-md dropdown-menu-end"
                      right
                      toggle-class="nav-btn"
                      variant="white"
                    >
                      <template v-slot:button-content>
                        <i class="bx bx-search-alt-2"></i>
                      </template>
                      <form class="p-3">
                        <div class="form-group m-0">
                          <div class="input-group">
                            <input
                              aria-label="Recipient's username"
                              class="form-control"
                              placeholder="Search ..."
                              type="text"
                            />
                            <div class="input-group-append">
                              <button class="btn btn-primary" type="submit">
                                <i class="mdi mdi-magnify"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-dropdown>
                  </li>
                  <li class="list-inline-item d-none d-sm-inline-block">
                    <b-dropdown
                      menu-class="dropdown-menu-end"
                      right
                      toggle-class="nav-btn"
                      variant="white"
                    >
                      <template v-slot:button-content>
                        <i class="bx bx-cog"></i>
                      </template>
                      <b-dropdown-item>View Profile</b-dropdown-item>
                      <b-dropdown-item>Clear chat</b-dropdown-item>
                      <b-dropdown-item>Muted</b-dropdown-item>
                      <b-dropdown-item>Delete</b-dropdown-item>
                    </b-dropdown>
                  </li>

                  <li class="list-inline-item">
                    <b-dropdown
                      menu-class="dropdown-menu-end"
                      right
                      toggle-class="nav-btn"
                      variant="white"
                    >
                      <template v-slot:button-content>
                        <i class="bx bx-dots-horizontal-rounded"></i>
                      </template>
                      <b-dropdown-item>Action</b-dropdown-item>
                      <b-dropdown-item>Another action</b-dropdown-item>
                      <b-dropdown-item>Something else</b-dropdown-item>
                    </b-dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="chat-users">
            <div class="chat-conversation p-3">
              <SimpleBar
                id="containerElement"
                ref="current"
                style="max-height: 470px"
              >
                <ul class="list-unstyled">
                  <li>
                    <div class="chat-day-title">
                      <span class="title">Today</span>
                    </div>
                  </li>
                  <li
                    v-for="data of chatMessagesData"
                    :key="data.message"
                    :class="{ right: `${data.align}` === 'right' }"
                  >
                    <div class="conversation-list">
                      <b-dropdown
                        menu-class="dropdown-menu-end"
                        variant="white"
                      >
                        <template v-slot:button-content>
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </template>
                        <b-dropdown-item>Copy</b-dropdown-item>
                        <b-dropdown-item>Save</b-dropdown-item>
                        <b-dropdown-item>Forward</b-dropdown-item>
                        <b-dropdown-item>Delete</b-dropdown-item>
                      </b-dropdown>
                      <div class="ctext-wrap">
                        <div class="conversation-name">{{ data.name }}</div>
                        <p>{{ data.message }}</p>
                        <p class="chat-time mb-0">
                          <i class="bx bx-time-five align-middle me-1"></i>
                          {{ data.time }}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </SimpleBar>
            </div>
            <div class="p-3 chat-input-section">
              <form class="row" @submit.prevent="formSubmit">
                <div class="col">
                  <div class="position-relative">
                    <input
                      v-model="form.message"
                      :class="{
                        'is-invalid': submitted && v$.form.message.$error,
                      }"
                      class="form-control chat-input rounded"
                      placeholder="Enter Message..."
                      type="text"
                    />
                    <div
                      v-if="submitted && v$.form.message.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.form.message.required.$message">{{
                          v$.form.message.required.$message
                        }}</span>
                    </div>
                    <div class="chat-input-links">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a
                            v-b-tooltip.hover
                            href="javascript: void(0);"
                            placement="top"
                            title="Emoji"
                          >
                            <i class="mdi mdi-emoticon-happy-outline"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            v-b-tooltip.hover
                            href="javascript: void(0);"
                            placement="top"
                            title="Images"
                          >
                            <i class="mdi mdi-file-image-outline"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            v-b-tooltip.hover
                            href="javascript: void(0);"
                            placement="top"
                            title="Add Files"
                          >
                            <i class="mdi mdi-file-document-outline"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-primary btn-rounded chat-send w-md"
                    type="submit"
                  >
                    <span class="d-none d-sm-inline-block me-2">Send</span>
                    <i class="mdi mdi-send"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end row -->
  </Layout>
</template>
